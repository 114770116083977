// Function when the DOM is ready
jQuery(($) => {
  // Logo hover
  $('#logo').hover(
    (e) => { $(e.target).removeClass('animated flipInX').addClass('animated pulse'); },
    (e) => { $(e.target).removeClass('animated pulse'); }
  );

  // Material ripple effect
  $('.rippled').each((i, el) => {
    $(el).on('click', (e) => {
      var self = e.currentTarget;
      var parent = $(self).parent();
      parent = $(self);

      // if (parent.find('.ink').length == 0)
      //   parent.prepend('<span class="ink"></span>');

      // var ink = parent.find('.ink');
      var ink = $(document.createElement('span'));
      ink.addClass('ink');
      parent.prepend(ink);
      // ink.removeClass('animate');

      if (!ink.height() && !ink.width()) {
        var d = Math.max(parent.outerWidth(), parent.outerHeight());
        ink.css({height: d, width: d});
      }

      var x, y;
      x = e.pageX - parent.offset().left - ink.width()  / 2;
      y = e.pageY - parent.offset().top  - ink.height() / 2;

      ink.css({top: y+'px', left: x+'px'}).addClass('animate');

      setTimeout(() => {
        ink.remove();
      }, 2000);
    });
  });

  // Add alt as 'title'
  //$('.wp-caption').each((i, el) => {
    //var alt = $('img', el).attr('alt') || false;
    //if (alt) {
      //var titleEl = document.createElement('span');
      //titleEl.innerText = alt;
      //titleEl.className = 'wp-caption-title';
      
      //$('.wp-caption-text', el).prepend(titleEl);
    //}
  //});

  // Global and specific hovers
  $('.hoverable, .wp-caption').each((i, el) => {
    $(el)
      .removeClass('hoverable')
      .hover(
        (e) => { $(e.currentTarget).addClass('hover'); },
        (e) => { $(e.currentTarget).removeClass('hover'); }
      );
  });

	$('.photoalbum .photo').on('click', (e) => {
		e.preventDefault();
		$(e.currentTarget).ekkoLightbox();
	});

  // Entrance animation for the sidebar cards
  // $('aside#sidebar article').each((i, elem) => {
  //   let delay = i * 100;
  //   $(elem).css({ "animation-delay": `${delay}ms` })
  //   $(elem).addClass('animated flipInX');
  // });
});
